import CustomContent from "@arcgis/core/popup/content/CustomContent.js";
import appConfig from "../../config/AppConfig";

interface LandaxDto {
    name: string;
    surveyUrl: string;
    surveyType: number;
    surveyTypeString: "Template" | "Draft" | "Filled";
}

const contentLandax = new CustomContent({
    outFields: ["a99_rds"],
    creator: async (event: any) => {
        const attributes = event.graphic.attributes;
        const landaxId = attributes["a99_rds"] || attributes["A99_RDS"];
        const result = await fetchLandaxData(landaxId);
        return render(result);
    }
});

const render = (result: LandaxDto[] | string) => {
    if (!Array.isArray(result))
        return `<div>
            ${renderTitle()}
            <div>${result}</div>
            </div>`;

    // order by surveyType
    result.sort((a, b) => a.surveyType - b.surveyType);

    // filter out mal / draft / utfylt
    const template = result.filter(survey => survey.surveyTypeString == "Template");
    const draft = result.filter(survey => survey.surveyTypeString == "Draft");
    const filled = result.filter(survey => survey.surveyTypeString == "Filled");

    return `<div>
            ${renderTitle()}
            ${renderList(template, "Maler")}
            ${renderList(draft, "Påbegynt")}
            ${renderList(filled, "Utfylt")}
            </div>`;
}

const renderList = (landaxDtos: LandaxDto[], postTitle: string) => {
    if (landaxDtos.length === 0)
        return ``;

    return `<div style="margin: .5em .7em">
            <div><b>${postTitle}:</b></div>
            <div>${landaxDtos.map(survey => "<a href='" + survey.surveyUrl + "' target='_blank'>" + survey.name + "</a>").join("<br />")}</div>
            </div>`;
}

const renderTitle = () => {
    return `<div style="font-size:1.0rem;font-weight:bold">Landax sjekklister</div>`;
}

const fetchLandaxData = async (landaxId: string) => {
    if (landaxId == null || landaxId == "" || landaxId == "-") {
        return "Ingen Landax ID for å hente Landax sjekkliste";
    }

    if (landaxId.startsWith("<") && landaxId.endsWith(">")) {
        return (`Invalid Landax ID: ${landaxId.replace("<", "&lt;").replace(">", "&gt;")}`);
    }

    const url = appConfig.app.apiUrl + "/v1/landax/survey/" + landaxId;
    let landaxData: LandaxDto[];
    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error(`Response status: ${response.status}`);
            return "Feil ved henting av Landax sjekkliste";
        }

        landaxData = await response.json();
        if (landaxData.length === 0) {
            console.debug("Ingen sjekkliste funnet i Landax for <i>'a99_rds'=" + landaxId + "</i>");
            return "Ingen sjekkliste funnet i Landax";
        }
        return landaxData;
    } catch (error) {
        console.error(error);
        return "Feil ved henting av Landax sjekkliste";
    }
}

export default contentLandax;