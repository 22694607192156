import { AttachmentsContent } from "@arcgis/core/popup/content";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import contentFieldsWithValue from "./popupCustomContent/FieldsWithValueContent";
import contentLandax from "./popupCustomContent/LandaxContent";
import { FieldsInfo2DLayer } from "./popupCustomContent/OutputFieldsScenes";

// Create a custom popup template
export const LandaxTemplate = (view: __esri.View): __esri.PopupTemplate => {
  return new PopupTemplate({
    overwriteActions: true,
    outFields: FieldsInfo2DLayer.map((field) => field.fieldName),
    title: "{a01_prosjektnavn}",
    content: [contentLandax, contentFieldsWithValue],
  });
};

export const AttachmentTemplate = (view: __esri.View): __esri.PopupTemplate => {
  const template = LandaxTemplate(view);
  template.outFields = ["*"];
  (template.content as __esri.ContentProperties[]).push(
    new AttachmentsContent({ displayType: "list" })
  );

  const popupTitle = (feature: __esri.Feature) => {
    return feature.graphic.layer.title + " {S_OBJID}";
  };

  template.title = popupTitle;
  return template;
};
