import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ArcGISViewContext from "../../../../context/ArcGISViewContext";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../store/rootReducer";

const CenterLineToggle = () => {
  const { activeView } = useContext(ArcGISViewContext);
  const centerLineLayer = activeView?.map.findLayerById("center_line");

  // Use this to put the center line layer on top whenever anything else is changed
  const projectPlanningData = useSelector(
    (state: StoreState) => state.projectPlanning.data
  );

  const [visible, setVisible] = useState<boolean>(centerLineLayer?.visible);

  useEffect(() => {
    if (!centerLineLayer) return;

    const layerLength = activeView?.map.layers.length;
    activeView?.map.layers.reorder(centerLineLayer, layerLength - 1);
  }, [visible, projectPlanningData]);

  const onChangeValue = () => {
    const newValue = !centerLineLayer?.visible;
    setVisible(newValue);
    centerLineLayer?.set("visible", newValue);
    (centerLineLayer as __esri.GroupLayer).layers.forEach((layer) => {
      layer.set("visible", newValue);
    });
  };

  return (
    <div style={{ display: "flex", paddingLeft: "10px" }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="center_line"
              checked={visible}
              onClick={onChangeValue}
              onTouchEnd={onChangeValue}
            />
          }
          label="Senterlinje"
        />
      </FormGroup>
    </div>
  );
};

export default CenterLineToggle;
