import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Card, Collapse, styled, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ArcGISViewContext from "../../../../../context/ArcGISViewContext";
import LoadingIndicator from "../../../../LoadingIndicator";
import LeafItem from "./LeafItem";

const ExpandCollapse = (props: { layerId: string }) => {
  const { layerId } = props;

  const [expanded, setExpanded] = useState<boolean>(false);
  const context = useContext(ArcGISViewContext);
  const [groupLayer, setGroupLayer] = useState<__esri.GroupLayer | null>(null);

  const CollapsibleItemMenuDiv = styled("div")(({ theme }) => ({
    padding: "5px 20px 5px 10px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    userSelect: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  }));

  useEffect(() => {
    setGroupLayer(
      context.activeView?.map.findLayerById(layerId) as __esri.GroupLayer
    );
  }, []);

  const expandCollapseIcon = expanded ? <ExpandLess /> : <ExpandMore />;

  return (
    <>
      <CollapsibleItemMenuDiv>
        <Card
          sx={{
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
            cursor: "pointer",
          }}
          elevation={0}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Typography sx={{ fontStyle: groupLayer?.title ? null : "italic" }}>
            {groupLayer?.title ?? "Laster..."}
          </Typography>
          {groupLayer ? (
            expandCollapseIcon
          ) : (
            <LoadingIndicator size={17} loading={true} />
          )}
        </Card>
      </CollapsibleItemMenuDiv>
      <Collapse in={expanded}>
        {groupLayer?.allLayers
          ?.toArray()
          .reverse()
          .map((l) => {
            return (
              <LeafItem key={l.id} layer={l as __esri.FeatureLayer}></LeafItem>
            );
          })}
      </Collapse>
    </>
  );
};

export default ExpandCollapse;
