// fields of value for popup for scenes
// ref mail til Endre fra Erik Sääf 21.08.2024
export const FieldsInfo2DLayer: Array<__esri.FieldInfo> = [
  { fieldName: "globalid", label: "globalid", visible: false },
  { fieldName: "a01_prosjektnavn", label: "A01_Prosjektnavn" },
  { fieldName: "a02_pns", label: "A02_PNS" },
  { fieldName: "a03_produsert_av", label: "A03_Produsert_av" },
  { fieldName: "a04_revisjon", label: "A04_Revisjon" },
  { fieldName: "a05_revisjonsdato", label: "A05_Revisjonsdato" },
  { fieldName: "a06_revisjonsmerknad", label: "A06_Revisjonsmerknad" },
  { fieldName: "a11_objektnavn", label: "A11_Objektnavn" },
  { fieldName: "a12_fagkode", label: "A12_Fagkode" },
  { fieldName: "a13_mmi", label: "A13_MMI" },
  { fieldName: "a14_stikningsdata", label: "A14_Stikningsdata" },
  { fieldName: "a20_link_url_", label: "A20_Link_URL_" },
  { fieldName: "a21_henvisning", label: "A21_Henvisning" },
  { fieldName: "a23_bemerkning", label: "A23_Bemerkning" },
  { fieldName: "c01_vegmodellnr", label: "C01_Vegmodellnr" },
  { fieldName: "a99_rds", label: "A99_RDS" },
  { fieldName: "g11_fra_kum", label: "G11_Fra_kum" },
  { fieldName: "g12_til_kum", label: "G12_Til_kum" },
  { fieldName: "g13_dimensjon", label: "G13_Dimensjon" },
  { fieldName: "g14_rørmateriale", label: "G14_Rørmateriale" },
  { fieldName: "g15_trykklasse", label: "G15_Trykklasse" },
  { fieldName: "g16_fall", label: "G16_Fall" },
  { fieldName: "g17_lengde", label: "G17_Lengde" },
  { fieldName: "g01_kumnummer", label: "G01_Kumnummer" },
  { fieldName: "g02_kumtype", label: "G02_Kumtype" },
  { fieldName: "g03_diameter", label: "G03_Diameter" },
  { fieldName: "g04_høyde_på_kum", label: "G04_Høyde_på_kum" },
  { fieldName: "g05_stige", label: "G05_Stige" },
  { fieldName: "g06_diameter_lokk", label: "G06_Diameter_lokk" },
  { fieldName: "g07_lokktype", label: "G07_Lokktype" },
  { fieldName: "c02_standardklasse", label: "C02_Standardklasse" },
  { fieldName: "c03_dim_hastighet", label: "C03_Dim_hastighet" },
  { fieldName: "c04_ådt", label: "C04_ÅDT" },
  { fieldName: "i11_type", label: "I11_Type" },
  { fieldName: "i15_dimensjon", label: "I15_Dimensjon" },
  { fieldName: "i16_spesifikasjon", label: "I16_Spesifikasjon" },
  { fieldName: "i17_lengde", label: "I17_Lengde" },
  { fieldName: "i19_bruksområde", label: "I19_Bruksområde" },
  { fieldName: "i01_kumnummer", label: "I01_Kumnummer" },
  { fieldName: "i02_kumtype", label: "I02_Kumtype" },
  { fieldName: "i03_skrå_topp", label: "I03_Skrå_topp" },
  { fieldName: "i04_ytre_dimensjon", label: "I04_Ytre_dimensjon" },
  { fieldName: "i05_høyde_på_kum", label: "I05_Høyde_på_kum" },
  { fieldName: "i06_materiale_kum", label: "I06_Materiale_kum" },
  { fieldName: "i07_materiale_lokk", label: "I07_Materiale_lokk" },
  { fieldName: "l01_pos_nr", label: "L01_Pos_nr" },
  { fieldName: "l21_stolpetype", label: "L21_Stolpetype" },
  { fieldName: "l22_stolpebeskrivelse", label: "L22_Stolpebeskrivelse" },
  { fieldName: "l31_fundamenttype", label: "L31_Fundamenttype" },
  { fieldName: "l32_fundamentbeskrivelse", label: "L32_Fundamentbeskrivelse" },
  { fieldName: "l11_skilttype", label: "L11_Skilttype" },
  { fieldName: "l12_produktnavn", label: "L12_Produktnavn" },
  { fieldName: "l13_skiltbeskrivelse", label: "L13_Skiltbeskrivelse" },
  {
    fieldName: "l14_skriltstørrelse_teksthøyde",
    label: "L14_Skriltstørrelse_Teksthøyde",
  },
  { fieldName: "l15_folieklasse", label: "L15_Folieklasse" },
  { fieldName: "a24_bemerkning", label: "A24_Bemerkning" },
  { fieldName: "o11_art", label: "O11_Art" },
  { fieldName: "o12_planteavstand", label: "O12_Planteavstand" },
  { fieldName: "n31_skapnummer", label: "N31_Skapnummer" },
  { fieldName: "n32_skaptype", label: "N32_Skaptype" },
  { fieldName: "n33_skapfarge", label: "N33_Skapfarge" },
  { fieldName: "n51_utstyrsnummer", label: "N51_Utstyrsnummer" },
  { fieldName: "n52_type_utstyr", label: "N52_Type_utstyr" },
  { fieldName: "n01_mastenummer", label: "N01_Mastenummer" },
  { fieldName: "n02_mastetype", label: "N02_Mastetype" },
  { fieldName: "n03_mastehøyde", label: "N03_Mastehøyde" },
  { fieldName: "n11_fundament_høyde", label: "N11_Fundament_høyde" },
  {
    fieldName: "n12_fundament_ytre_dimensjon",
    label: "N12_Fundament_ytre_dimensjon",
  },
  { fieldName: "n04_ytelsesklasse", label: "N04_Ytelsesklasse" },
  { fieldName: "n05_mastefarge", label: "N05_Mastefarge" },
  { fieldName: "n21_armaturnummer", label: "N21_Armaturnummer" },
  { fieldName: "n22_armaturtype", label: "N22_Armaturtype" },
  { fieldName: "n23_armatureffekt", label: "N23_Armatureffekt" },
  { fieldName: "n24_lysstyrke", label: "N24_Lysstyrke" },
  { fieldName: "n25_armaturfarge", label: "N25_Armaturfarge" },
  { fieldName: "n26_utliggerlengde", label: "N26_Utliggerlengde" },
  { fieldName: "a22_henvisning", label: "A22_Henvisning" },
  { fieldName: "o01_tykkelse", label: "O01_tykkelse" },
  { fieldName: "o21_fraksjon", label: "O21_Fraksjon" },
  { fieldName: "o31_type", label: "O31_Type" },
  { fieldName: "o41_type", label: "O41_Type" },
  { fieldName: "k_a14_stikningsdata", label: "K_A14_Stikningsdata" },
  { fieldName: "k_p01_mmi", label: "K_P01_MMI" },
  { fieldName: "k_p02_revisjon", label: "K_P02_Revisjon" },
  { fieldName: "k_p03_revisjonsdato", label: "K_P03_Revisjonsdato" },
  { fieldName: "k_p04_revisjonsmerknad", label: "K_P04_Revisjonsmerknad" },
  { fieldName: "k_p05_vegnummer", label: "K_P05_Vegnummer" },
  { fieldName: "k_p06_pns", label: "K_P06_PNS" },
  { fieldName: "k_p08_konstruksjonsnavn", label: "K_P08_Konstruksjonsnavn" },
  {
    fieldName: "k_p15_fagmodellbeskrivelse",
    label: "K_P15_Fagmodellbeskrivelse",
  },
  { fieldName: "k_p19_prosjektnummer", label: "K_P19_Prosjektnummer" },
  { fieldName: "k_p21_koordinatsystem", label: "K_P21_Koordinatsystem" },
  { fieldName: "k_p22_høydesystem", label: "K_P22_Høydesystem" },
  { fieldName: "k_p28_oversiktstegning", label: "K_P28_Oversiktstegning" },
  {
    fieldName: "k_p70_link_url_arbeidsgrunnlag_",
    label: "K_P70_Link_URL_arbeidsgrunnlag_på_TC",
  },
  { fieldName: "k_a01_prosjektnavn", label: "K_A01_Prosjektnavn" },
  { fieldName: "k_a02_pns", label: "K_A02_PNS" },
  { fieldName: "k_a04_revisjon", label: "K_A04_Revisjon" },
  { fieldName: "k_a05_revisjonsdato", label: "K_A05_Revisjonsdato" },
  { fieldName: "k_a06_revisjonsmerknad", label: "K_A06_Revisjonsmerknad" },
  { fieldName: "k_a12_fagkode", label: "K_A12_Fagkode" },
  { fieldName: "k_a13_mmi", label: "K_A13_MMI" },
  { fieldName: "k_a17_material", label: "K_A17_Material" },
  { fieldName: "k_a20_link_url_", label: "K_A20_Link_URL_" },
  {
    fieldName: "k_a32_konstruksjonsnummer",
    label: "K_A32_Konstruksjonsnummer",
  },
  { fieldName: "k_a33_akse", label: "K_A33_Akse" },
  { fieldName: "k_a50_merknad_1", label: "K_A50_Merknad_1" },
  { fieldName: "k_a51_merknad_2", label: "K_A51_Merknad_2" },
  { fieldName: "k_a99_rds", label: "K_A99_RDS" },
  { fieldName: "k_a52_merknad_3", label: "K_A52_Merknad_3" },
  { fieldName: "k_a40_produktbeskrivelse", label: "K_A40_Produktbeskrivelse" },
  { fieldName: "k_a53_merknad_4", label: "K_A53_Merknad_4" },
  { fieldName: "k_a54_merknad_5", label: "K_A54_Merknad_5" },
  { fieldName: "k_a55_merknad_6", label: "K_A55_Merknad_6" },
  { fieldName: "k_a56_merknad_7", label: "K_A56_Merknad_7" },
  { fieldName: "k_a57_merknad_8", label: "K_A57_Merknad_8" },
] as Array<__esri.FieldInfo>;

export const FieldsInfoName2DLayer: Array<string> = FieldsInfo2DLayer.map(
  (field) => field.fieldName
);
