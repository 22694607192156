import LoadingIcon from "../assets/icons/loading-indicator.svg";

const LoadingIndicator = (props: {
  size: number;
  loading: boolean;
  screenOverlay?: boolean;
}) => {
  const { size, loading, screenOverlay = false } = props;

  return loading ? (
    <div
      className="loading-indicator"
      style={{
        position: screenOverlay ? "absolute" : "inherit",
        top: `calc(50% - ${size / 2}px)`,
        left: `calc(50% - ${size / 2}px)`,
        animation: "spin 2s linear infinite",
        opacity: 0.3,
        width: size,
        height: size,
        zIndex: 1000,
      }}
    >
      <img src={LoadingIcon} alt="Loading..." width={size} height={size} />
    </div>
  ) : null;
};

export default LoadingIndicator;
