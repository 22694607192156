import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Card, Collapse, Divider, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import appConfig, { UILocation } from "../../../../config/AppConfig";
import ArcGISViewContext from "../../../../context/ArcGISViewContext";
import {
  useCreateLoadAGSLayers,
  useHookLayer,
} from "../../../../hooks/useCreateLoadAGSLayers";
import { loadProjectMetaData } from "../../../../map/MapServiceFactoryUtils";
import { FieldsInfoName2DLayer } from "../../../../map/popupCustomContent/OutputFieldsScenes";
import { LandaxTemplate } from "../../../../map/PopupTemplate";
import {
  getAllLayerIds,
  loadProjectLayers,
} from "../../../../store/projectPlanningSlice";
import { StoreState } from "../../../../store/rootReducer";
import Submenu from "../Submenu";
import SubmenuTitle from "../SubmenuTitle";
import CenterLineToggle from "./CenterLineToggle";
import ExpandCollapse from "./PilotData/ExpandCollapse";
import ProjectPlanningDataLoading from "./ProjectPlanningDataLoading";

const ProjectPlanning = () => {
  const [loadingMetadata, setLoadingMetadata] = useState<boolean>(false);
  const projectPlanningMetadata = useSelector(
    (state: StoreState) => state.projectPlanning.data
  );
  const dispatch = useDispatch();
  const { activeView } = useContext(ArcGISViewContext);
  const [loadLayers, setLoadLayers] = useState<useHookLayer[]>([]);
  const [pilotDataExpanded, setPilotDataExpanded] = useState(false);
  const { loadedLayers, loading, error } = useCreateLoadAGSLayers(
    loadLayers,
    activeView?.type ?? "2d",
    true,
    true
  );

  // on init, only once, load metadata for project planning layers and set in store
  useEffect(() => {
    setLoadingMetadata(true);

    // Prevent reloading data and erasing current state every time the menu is rendered
    if (Object.keys(projectPlanningMetadata).length > 0) {
      setLoadingMetadata(false);
      return;
    }

    const loadMetaData = async () => {
      let attributesArray = await loadProjectMetaData();
      dispatch(loadProjectLayers({ attributesArray }));
      setLoadingMetadata(false);
    };

    loadMetaData();
  }, []);

  // create and load layers when metadata is loaded. Add to View
  useEffect(() => {
    if (Object.keys(projectPlanningMetadata).length === 0) return;

    const layerData = Array.from(
      getAllLayerIds(projectPlanningMetadata, activeView?.type)
    );

    const layers = layerData.map((dataPiece) => {
      const [layerId, layerType] = dataPiece.split("|");
      let props = {
        id: layerId,
        visible: false,
        portalItem: {
          id: layerId,
        },
      };

      // TODO: Add outfields to 3D layers???
      // if (activeView.type === "2d") {
      (props as __esri.FeatureLayer).outFields = FieldsInfoName2DLayer;
      // }

      // add template to both 2D and 3D layers
      (props as __esri.FeatureLayer).popupTemplate = LandaxTemplate(activeView);

      return {
        type: layerType,
        layerprops: props,
      } as useHookLayer;
    });

    setLoadLayers(layers);
  }, [projectPlanningMetadata, activeView?.type]);

  const pilotDataLayers = appConfig.layers.filter(
    (layer) => layer.uiLocation === UILocation.PilotData
  );

  return (
    <Submenu className="submenu-layers">
      <SubmenuTitle text={I18n.t("sidebar.projectPlanning.title")} />
      <div className="sidebar-submenu-content">
        <ProjectPlanningDataLoading
          loadingMetadata={loadingMetadata}
          loadingServices={loading || loadedLayers.length === 0}
          error={error as unknown as boolean}
          projectPlanningMetadata={projectPlanningMetadata}
        />
        {activeView?.type === "2d" ? (
          <>
            <Divider sx={{ margin: 1 }} />
            <CenterLineToggle />
            <Divider sx={{ margin: 1 }} />
            <Card
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                cursor: "pointer",
              }}
              onClick={() => setPilotDataExpanded(!pilotDataExpanded)}
            >
              <Typography fontSize={20} fontWeight={"bold"}>
                <Translate value="sidebar.projectPlanning.fromPilot" />
              </Typography>
              {pilotDataExpanded ? <ExpandLess /> : <ExpandMore />}
            </Card>
            <Collapse in={pilotDataExpanded}>
              {pilotDataLayers.map((layer) => (
                <ExpandCollapse
                  key={layer.id}
                  layerId={layer.id!}
                ></ExpandCollapse>
              ))}
            </Collapse>
          </>
        ) : null}
      </div>
    </Submenu>
  );
};

export default ProjectPlanning;
