import { Extent } from "@arcgis/core/geometry";
import appConfig from "../config/AppConfig";
import Query from "@arcgis/core/rest/support/Query";
import * as query from "@arcgis/core/rest/query";

// fetch extent from WMS GetCapabilities for NTM (ESPG:5116) drone photos
const loadAndAddDroneExtent = async (url: string): Promise<Array<{ layerName: string | null | undefined; extent: any; }>> => {
    const response = await fetch(url + "?request=GetCapabilities");
    const data = await response.text();

    // Parse the XML string
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(data, "text/xml");

    // Find layers with CRS="EPSG:5116"
    const layers = xmlDoc.querySelectorAll('Layer BoundingBox[CRS="EPSG:5116"]');

    // Extract BoundingBox information
    const boundingBoxes = Array.from(layers).map((l) => ({
        layerName: l.parentNode?.querySelector("Name")?.textContent,
        extent: new Extent({
            // swap x and y
            ymin: parseFloat(l.getAttribute("minx") ?? "0"),
            xmin: parseFloat(l.getAttribute("miny") ?? "0"),
            ymax: parseFloat(l.getAttribute("maxx") ?? "0"),
            xmax: parseFloat(l.getAttribute("maxy") ?? "0"),
            spatialReference: { wkid: 5116 },
        }),
    }));
    // console.log(boundingBoxes)
    return boundingBoxes;
};

export const addSublayersFullExtent = async (layer: __esri.WMSLayer) => {
    // set missing sublayers fullExtent, if missing
    if (layer.sublayers && layer.sublayers.getItemAt(0).fullExtent === null) {
        const fullExtent = await loadAndAddDroneExtent(layer.url);
        layer.sublayers.forEach(sublayer => {
            const ext = fullExtent.find((b) => b.layerName === sublayer.title)?.extent;
            if (ext && ext.xmax !== 0) {
                sublayer.fullExtent = ext;
            }
        });
    };
};

export const setVisibleSubLayers = (layer: __esri.WMSLayer) => {
    // set visible sublayers using customVisibleSublayers property
    const visibleSubLayers = (layer as any).customVisibleSublayers; // custom prop set on layer from config
    if (visibleSubLayers) {
        layer.sublayers.forEach((sublayer: any) => {
            sublayer.visible = visibleSubLayers.includes(sublayer.title);
        });
    }
};

/// Load metadata for project planning layers
export const loadProjectMetaData = async () => {
    let queryLoadData = new Query({
        where: "1=1",
        returnGeometry: true,
        outFields: ["*"],
    });

    const fSet = await query.executeQueryJSON(appConfig.projectPlanning.metadataTableUrl, queryLoadData);
    const attributesArray = fSet.features.map((feature) => feature.attributes) as Array<{ [key: string]: string | number }>;
    return attributesArray;
};