import { Typography } from "@mui/material";
import { ProjectPlanningNodeType } from "../../../../store/projectPlanningSlice";
import { Translate } from "react-redux-i18n";
import NestedLayerItem from "./NestedLayerItem";

const ProjectPlanningDataLoading = (props: {
  loadingMetadata: boolean;
  loadingServices: boolean;
  error: boolean;
  projectPlanningMetadata: ProjectPlanningNodeType;
}) => {
  const { loadingMetadata, loadingServices, projectPlanningMetadata, error } =
    props;

  if (loadingMetadata) {
    return (
      <div className="info">
        <Typography>
          <Translate value="shared.loading"></Translate>
        </Typography>
      </div>
    );
  }

  if (loadingServices) {
    return (
      <div className="info">
        <Typography>
          <Translate value="shared.loadingServices"></Translate>
        </Typography>
      </div>
    );
  }

  const noData = Object.keys(projectPlanningMetadata).length === 0;

  return (
    <div
      style={{
        padding: noData ? "1em 10px 1em 10px" : 0,
      }}
    >
      {" "}
      {error && (
        <div className="warning">
          <Typography>
            <Translate value="shared.error"></Translate>
          </Typography>
          <span>{error}</span>
        </div>
      )}
      {noData ? (
        <div className="info">
          <Typography>
            <Translate value="shared.nodata"></Translate>
          </Typography>
        </div>
      ) : (
        <NestedLayerItem
          projectPlanningMetadata={projectPlanningMetadata}
          parentKeys={[]}
        />
      )}
    </div>
  );
};

export default ProjectPlanningDataLoading;
