import LayerList, { LayerListType } from "../../../layers/LayerList";
import LayerListItem from "../../../layers/LayerListItem";
import Submenu from "../Submenu";
import SubmenuTitle from "../SubmenuTitle";
import { I18n } from "react-redux-i18n";
import "../../../../styles/Layers.css";
import WMSSublayer from "@arcgis/core/layers/support/WMSSublayer";
import { useContext, useEffect, useState } from "react";
import ArcGISViewContext from "../../../../context/ArcGISViewContext";

const SubmenuMedia = () => {
  const [sublayers, setSublayers] = useState<WMSSublayer[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { activeView } = useContext(ArcGISViewContext)

  const onZoomToLayer = (layer: __esri.Layer) => {
    if (layer.fullExtent) {
      activeView.goTo(layer.fullExtent);
    }
  };

  const onLayerClick = (layer: __esri.Layer) => {
    if (activeView) {
      layer.visible = !layer.visible;
    }
  };

  // get sublayers from WMS layer when mapview is loaded
  useEffect(() => {
    if (activeView && sublayers.length === 0) {
      // get layer from view
      const droneWMS = activeView.map.allLayers.find(layer => {
        return layer.title === "Drone WMS";
      }) as __esri.WMSLayer;

      if (droneWMS == null) {
        setErrorMessage("WMS with title 'Drone WMS' not found in map")
        return
      }

      if (droneWMS?.loadStatus === "failed") {
        setErrorMessage("WMS failed to load. Error: " + droneWMS.loadError?.message)
        return
      }

      if (droneWMS?.sublayers == null) {
        setErrorMessage("No sublayers found in WMS layer")
        return
      }

      droneWMS.visible = true;
      setSublayers(droneWMS.sublayers.toArray());
    }
  }, [activeView]);

  return (
    <Submenu className="submenu-layers">
      <SubmenuTitle text={I18n.t("sidebar.media.title")} />
      <div className="sidebar-submenu-content">
        <LayerList
          title={I18n.t("sidebar.media.drone")}
          errorMessage={errorMessage}
          initOpened={true}
          layerListType={LayerListType.Top}
        >
          {sublayers.map((layer) => (
            <LayerListItem key={layer.id} layer={layer} onLayerClick={onLayerClick} onZoomToLayer={onZoomToLayer} />
          ))}
        </LayerList>
      </div>
    </Submenu>
  );
};

export default SubmenuMedia;
